import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { SellerCnpjReceive } from '../models/sellerCnpjReceive.class';
import { UserLogged } from '../models/userLogged.class';
import { InfoSecurityService } from './info-security.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  urlLogin = '';

  securityOnOff = true; // true = segurança ativada, false = segurança desativada não exige token

  constructor(
    private serviceInfoUser: InfoSecurityService,
    private router: Router
  ) {
    this.urlLogin = environment.urlLogin;
  }

  isLoggedIn() {
    if (!this.securityOnOff) {
      return true;
    }
    let isLogged = false;
    var count = 0;

    isLogged =
      localStorage.getItem('key') && !this.isAccessTokenInvalid()
        ? true
        : false;
    console.log(`status token ${isLogged}`);
    var attempt = 0;
    if (!sessionStorage.getItem('attempt')) {
      sessionStorage.setItem('attempt', '0');
    } else {
      attempt = Number.parseInt(sessionStorage.getItem('attempt'));
    }
    console.log(`Tentativa verificar token ... ${attempt}`);
    if (!isLogged && attempt > 3) {
      parent.location.href = `${this.urlLogin}`;
    } else if (!isLogged) {
      attempt++;
      sessionStorage.setItem('attempt', JSON.stringify(attempt));
    }
    if (isLogged) {
      attempt = 0;
      sessionStorage.setItem('attempt', JSON.stringify(attempt));
    }
    return isLogged;
  }

  isAccessTokenInvalid() {
    const token = localStorage.getItem('key');
    //console.log(`Verify request token ${token}`);
    return !token || this.jwtHelper.isTokenExpired(token);
  }

  async getUserlogged() {
    var userLogged = new UserLogged();
    console.log('verificando sellerId localStorage ...');
    const sellerId = localStorage.getItem('sellerId');
    console.log(sellerId);
    const ret = <SellerCnpjReceive>(
      await this.serviceInfoUser.getCnpjBySeller(sellerId).toPromise()
    );
    console.log(ret);
    if (
      this.router.url.includes('cancellation-request') ||
      this.router.url.includes('cancellation-reasons')
    )
      return ret;
    if (!ret.seller || !ret.seller.cnpj || ret.seller.cnpj == '') {
      console.log('cnpj não encontrado ...');
      this.router.navigate(['/not-finances']);
      return;
    }
    userLogged.cnpj = ret.seller.cnpj;
    userLogged.userType = ret.userType;
    return userLogged;
  }
} // close class
