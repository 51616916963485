import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from 'src/app/service/loading.service';

@Component({
  selector: 'app-iframe-listener',
  templateUrl: './iframe-listener.component.html',
  styleUrls: ['./iframe-listener.component.scss']
})

export class IframeListenerComponent implements OnInit {

  load = false;

  constructor(
    private router: Router,
    ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let url = (<NavigationEnd>event).url;
        if (url.length > 1)//don't post message about base route '/'
          window.parent.postMessage({ "for": "dashboard", "operation": "changeroute", "route": url, action: "changeURL" }, '*')
      }
    });
  }

  ngOnInit(): void {
    LoadingService.loadChange.subscribe( ret => this.load = ret);
  }

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    const { action, data } = e.data;
    if (action === 'routing') {
      this.router.navigate([data]);
    }
    else if (action === 'credentials') {
      window.localStorage.clear();
      for (let prop in data) {
        window.localStorage.setItem(prop, data[prop]);
      }
    }
    else if (action === 'clearCredentials') {
      window.localStorage.clear();
    }
    console.log("Message Funcionando");
  }

  @HostListener('window:beforeunload', ['$event'])
  onCloseApp($event) {
    console.log('limpando localstorage financial');
    window.localStorage.clear();
  }
}

window.onunload = () => {
  console.log('limpando localstorage financial unload');
  window.localStorage.clear();
}
