import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';
import { SellerNotFinancesComponent } from './shared/seller-not-finances/seller-not-finances.component';

const routes: Routes = [
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: 'not-finances', component: SellerNotFinancesComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  {
    path: '',
    loadChildren: () => import('./pages/order-inquiry/order-inquiry.page.module')
      .then(m => m.OrderInquiryModule)
  },
  {
    path: 'statement-payment', //OK
    loadChildren: () => import('./pages/statement-payment-fast/statement-payment-fast.module')
      .then(m => m.StatementPaymentFastModule)
  },
  {
    path: 'statement-payment-fast', //OK
    loadChildren: () => import('./pages/statement-payment-fast/statement-payment-fast.module')
      .then(m => m.StatementPaymentFastModule)
  },
  {
    path: 'parameterization-rates', //OK
    loadChildren: () => import('./pages/parameterization-rates/parameterization-rates.module')
      .then(m => m.ParameterizationRatesModule)
  },
  {
    path: 'registration-request', //OK
    loadChildren: () => import('./pages/registration-request/registration-request.module')
      .then(m => m.RegistrationRequestModule)
  },
  {
    path: 'list-tracking', //OK
    loadChildren: () => import('./pages/list-tracking/list-tracking.module')
      .then(m => m.ListTrackModule)
  },
  {
    path: 'cancellation-request',
    loadChildren: () => import('./pages/cancellation-request/cancellation-request.module')
      .then(m => m.CancellationRequestModule)
  },
  {
    path: 'cancellation-reasons',
    loadChildren: () => import('./pages/cancellation-reasons/cancellation-reasons.module')
      .then(m => m.CancellationReasonsModule)
  },
  { path: '**', redirectTo: 'not-found' } // deixar este path sempre por ultimo 

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
