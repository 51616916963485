import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorInterceptor implements HttpInterceptor {
  token = 'Giwo94Nj7Ol7qCsvquw3SEz2aS21yiWa';
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.auth.isAccessTokenInvalid() && this.auth.securityOnOff) {
      const authReq = request.clone({
        headers: request.headers
          .set('token', this.token)
          .set('Authorization', `Bearer ${localStorage.getItem('key')}`)
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT'),
      });
      return next.handle(authReq);
    }

    const authReq = request.clone({
      headers: request.headers.set('token', this.token),
    });
    return next.handle(authReq);
  }
} // close class
